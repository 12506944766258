.Modal {
  z-index: 9999;
  @apply fixed inset-0 overflow-y-auto box-content;
}

.Modal__Wrapper__Square {
  @apply flex min-h-screen max-h-screen !box-border items-center justify-center text-center sm:block mx-5 pb-16;
}

.Modal__Wrapper {
  @apply flex min-h-screen max-h-screen !box-border items-start justify-center text-center sm:block p-8;
}

.Modal__Overlay {
  @apply fixed inset-0 h-full w-full bg-gray-500 bg-opacity-75;
}

.Modal__Trick {
  @apply hidden sm:inline-block sm:h-screen sm:align-middle;
}

.Modal__Inner {
  @apply inline-block text-slate-900 mb-4 transform overflow-hidden text-left align-bottom shadow-xl transition-all w-full min-h-full sm:my-8 sm:align-middle;
}

.Modal__Inner__Wrapper {
  @apply absolute right-0 top-0 block px-4 py-4 rtl:left-0;
}

.Modal__Close {
  @apply relative z-20 rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2;
}

.Modal__Close > span {
  @apply sr-only;
}

.Modal__Close__Icon {
  @apply h-6 w-6;
}

.Modal__Content {
  @apply mt-3 text-center sm:mt-0 sm:text-left;
}

.Modal__Title {
  @apply mb-4 text-xl font-bold uppercase rtl:text-right;
}

.Modal__Description {
  @apply mt-2;
}

.Modal__Description > p {
  @apply mb-3 text-sm;
}

.Modal__Description > h5 {
  @apply font-display mb-2 text-sm font-bold uppercase;
}

.Modal__Description > h6 {
  @apply font-display mb-2 text-sm uppercase;
}

.ModalError__Top {
  @apply mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100;
}

.ModalError__Icon {
  @apply h-6 w-6 text-red-600;
}

.ModalError__Title {
  @apply mt-4;
}

.ModalError__Title > p {
  @apply text-center text-sm text-gray-500;
}

.ModalError__Action {
  @apply mt-5 sm:mt-6;
}

.ModalError__Button {
  @apply inline-flex w-full justify-center rounded border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white hover:opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm;
}

.Modal__Inner__CarouselOnly {
  @apply !aspect-square;
}

@media (orientation: landscape) {
  .Modal__Inner__CarouselOnly,
  .Modal__Inner__CarouselContent {
    @apply !w-auto !h-full;
  }
}
@media (orientation: portrait) {
  .Modal__Inner__CarouselOnly,
  .Modal__Inner__CarouselContent {
    @apply !w-full !h-auto;
  }
}
