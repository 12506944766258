.OptionsSelectorCompact:nth-of-type(odd) {
  @apply bg-white;
}

.OptionsSelectorCompact:nth-of-type(even) {
  @apply bg-gray-50;
}

.OptionsSelectorCompact {
  @apply shadow-lg my-6 rounded-md px-6 py-4;
}

.OptionsSelector__OptionsContainer select {
  @apply border-l-0 rounded-none border border-[#828282];
}

.OptionsSelector__OptionsContainer select:first-of-type {
  @apply border-l-0;
}

.OptionsSelector__OptionsContainer select:last-of-type {
  @apply border-l-0 rounded-r-full;
}
